<template>
  <div class="gv-body-wrapper">
   <Header />
    <article
     v-if="artileData"
      id="post-10874"
      class="post-10874 post type-post status-publish format-standard has-post-thumbnail hentry category-askgaryvee category-life-lessons"
    > 
      <ArticleDetail :articleImage="artileData.articleImage"/>
      <div class="gv-single-wrapper">
        <div class="container">
          <div class="row">
            <div class="gv-single-content col-md-8">
              <h1 class="gv-ctrl-title gv-liner">
                <a href="index.html" title="A Letter I Wrote In A Locker Room"
                  >{{artileData.title}}</a
                >
              </h1>

              
              <div class="gv-post-author row">
                <div class="col-xs-12 col-sm-7" v-if="artileData.Author">
                  <div class="gv-post-avatar" >
                    <img
                      :src="artileData.Author.profile_img"
                      width="96"
                      height="96"
                      alt="Garyvee"
                      class="avatar avatar-96 wp-user-avatar wp-user-avatar-96 alignnone photo"
                    />
                  </div>
                  <div class="gv-post-author-links">
                    <h5 class="gv-h-slab" v-if="artileData.Author.name">
                      by
                      <a
                        href="#"
                        target="_blank"
                        class="link-blue link-www"
                        >{{artileData.Author.name}}</a
                      >
                    </h5>
                    <h5 class="gv-h-slab">
                      <a
                        class="sl-twitter"
                        href="#"
                        target="_blank"
                      >
                        <i class="fa fa-twitter"></i>
                        <span class="sl-twitter-handle">{{artileData.Author.email}}</span>
                      </a>
                    </h5>
                  </div>
                </div>


                <div class="col-xs-12 col-sm-5">
                  <div class="gv-post-sharing topbar">
                    <div class="row">
                      <div class="col-xs-3">
                        <a
                          href="#"
                          data-permalink="#"
                          data-platform="facebook"
                          class="gv-ctrl-share gv-btn gv-btn-block gv-btn-short gv-btn-s-fb"
                          title="Share on Facebook"
                          ><i class="fa fa-facebook"></i
                        ></a>
                      </div>
                      <div class="col-xs-3">
                        <a
                          href="#"
                          data-permalink="#"
                          data-platform="twitter"
                          class="gv-ctrl-share gv-btn gv-btn-block gv-btn-short gv-btn-s-tw"
                          title="Tweet on Twitter"
                          ><i class="fa fa-twitter"></i
                        ></a>
                      </div>
                      <div class="col-xs-3">
                        <a
                          href="#"
                          data-permalink="#"
                          data-platform="linkedin"
                          class="gv-ctrl-share gv-btn gv-btn-block gv-btn-short gv-btn-s-in"
                          title="Share on LinkedIn"
                          ><i class="fa fa-linkedin"></i
                        ></a>
                      </div>
                      <div class="col-xs-3">
                        <a
                          href="#"
                          data-permalink="#"
                          data-platform="pinterest"
                          class="gv-ctrl-share gv-btn gv-btn-block gv-btn-short gv-btn-s-pin"
                          title="Share on Pinterest"
                          ><i class="fa fa-pinterest"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                  <h6 class="gv-h-slab gv-post-date text-right">
                      {{new Date(artileData.createdAt).toLocaleDateString()}}  <span class="middot">-</span>{{new Date(artileData.createdAt).toLocaleTimeString()}} 
                  </h6>
                </div>
              </div>


              <div id="shubham-data" class="gv-post-content clearfix" v-html="artileData.description">
                
              </div>
              <!-- <div class="gv-post-footer">
                <div class="gv-post-sharing clearfix">
                  <div class="col-xs-4 col-sm-3">
                    <a
                      href="#"
                      data-permalink="https://www.garyvaynerchuk.com/a-letter-i-wrote-in-a-locker-room/"
                      data-platform="facebook"
                      class="gv-ctrl-share gv-btn gv-btn-block gv-btn-s-fb"
                      title="Share on Facebook"
                    >
                      <i class="fa fa-facebook"></i>
                    </a>
                  </div>
                  <div class="col-xs-4 col-sm-3">
                    <a
                      href="#"
                      data-permalink="https://www.garyvaynerchuk.com/a-letter-i-wrote-in-a-locker-room/"
                      data-platform="twitter"
                      class="gv-ctrl-share gv-btn gv-btn-block gv-btn-s-tw"
                      title="Tweet on Twitter"
                    >
                      <i class="fa fa-twitter"></i>
                    </a>
                  </div>
                  <div class="hidden-xs col-sm-3">
                    <a
                      href="#"
                      data-permalink="https://www.garyvaynerchuk.com/a-letter-i-wrote-in-a-locker-room/"
                      data-platform="linkedin"
                      class="gv-ctrl-share gv-btn gv-btn-block gv-btn-s-in"
                      title="Share on LinkedIn"
                    >
                      <i class="fa fa-linkedin"></i>
                    </a>
                  </div>
                  <div class="col-xs-4 col-sm-3">
                    <a
                      href="#overlay-share|modal-open"
                      class="gv-btn gv-btn-block gv-btn-red gv-modal-ctrl"
                      title="Share"
                    >
                      <span class="hidden-xs">Share&nbsp;&nbsp;</span>
                      <i class="fa fa-share"></i>
                    </a>
                  </div>
                </div>

                <div class="gv-post-comments">
                  <div
                    class="fb-comments"
                    data-width="100%"
                    data-href="https://www.garyvaynerchuk.com/a-letter-i-wrote-in-a-locker-room/"
                    data-numposts="10"
                  ></div>
                </div>


                <div class="gv-post-tags">
                  <a
                    href="https://www.garyvaynerchuk.com/category/askgaryvee/"
                    class="link-blue"
                    >#AskGaryVee</a
                  >
                  <a
                    href="https://www.garyvaynerchuk.com/category/life-lessons/"
                    class="link-blue"
                    >#Life Lessons</a
                  >
                </div>
              </div> -->
              <!-- <div class="gv-post-suggested">
                <h3 class="gv-h-welter50">Check this out</h3>
                <article
                  id="post-10863"
                  class="gv-post post-10863 post type-post status-publish format-standard has-post-thumbnail hentry category-askgaryvee category-life-lessons"
                >
                  <div class="gv-post-img">
                    <a
                      href="https://www.garyvaynerchuk.com/the-18-to-28-debate-is-college-worth-it/"
                    >
                      <img
                        src="https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_62696679D1F5-1-700x560.jpeg"
                        class="attachment-large size-large wp-post-image"
                        alt=""
                        srcset="
                          https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_62696679D1F5-1-700x560.jpeg  700w,
                          https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_62696679D1F5-1-250x200.jpeg  250w,
                          https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_62696679D1F5-1-768x614.jpeg  768w,
                          https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_62696679D1F5-1-120x96.jpeg   120w,
                          https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_62696679D1F5-1.jpeg         1400w
                        "
                        sizes="(max-width: 700px) 100vw, 700px"
                      />
                    </a>
                  </div>
                  <div class="row clearfix gv-post-sharemeta">
                    <div class="col-sm-5 col-md-6 gv-post-tags">
                      <a
                        href="https://www.garyvaynerchuk.com/category/askgaryvee/"
                        class="link-blue"
                        >#AskGaryVee</a
                      >
                      <a
                        href="#"
                        data-permalink="https://www.garyvaynerchuk.com/the-18-to-28-debate-is-college-worth-it/"
                        class="gv-btn gv-btn-white gv-post-mobileshare pull-right visible-xs-inline-block"
                      >
                        Share <i class="fa fa-share"></i>
                      </a>
                    </div>
                    <div
                      class="col-xs-5 col-sm-6 col-md-6 pull-right text-right"
                    >
                      <div class="gv-post-sharing hidden-xs">
                        <div class="row">
                          <div class="col-xs-4">
                            <a
                              href="#"
                              data-permalink="https://www.garyvaynerchuk.com/the-18-to-28-debate-is-college-worth-it/"
                              data-platform="facebook"
                              class="gv-ctrl-share gv-btn gv-btn-block gv-btn-short gv-btn-s-fb"
                              title="Share on Facebook"
                              ><i class="fa fa-facebook"></i
                            ></a>
                          </div>
                          <div class="col-xs-4">
                            <a
                              href="#"
                              data-permalink="https://www.garyvaynerchuk.com/the-18-to-28-debate-is-college-worth-it/"
                              data-platform="twitter"
                              class="gv-ctrl-share gv-btn gv-btn-block gv-btn-short gv-btn-s-tw"
                              title="Tweet on Twitter"
                              ><i class="fa fa-twitter"></i
                            ></a>
                          </div>
                          <div class="col-xs-4">
                            <a
                              href="#"
                              data-permalink="https://www.garyvaynerchuk.com/the-18-to-28-debate-is-college-worth-it/"
                              data-platform="linkedin"
                              class="gv-ctrl-share gv-btn gv-btn-block gv-btn-short gv-btn-s-in"
                              title="Share on LinkedIn"
                              ><i class="fa fa-linkedin"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-sm-6">
                      <h1 class="gv-ctrl-title gv-post-title gv-liner-simple">
                        <a
                          href="https://www.garyvaynerchuk.com/the-18-to-28-debate-is-college-worth-it/"
                          >The 18 to 28 Debate: Is College Worth It?</a
                        >
                      </h1>
                    </div>
                    <div class="col-sm-6 text-left">
                      <h5 class="gv-h-slab gv-post-date">
                        2 months ago <span class="middot">&middot;</span> 7 min
                        read
                      </h5>
                      <p>
                        The world is changing, and it’s important to acknowledge
                        that certain long held beliefs should be re-examined. So
                        many of us are stuck doing things in an outdated way and
                        it’s only leading us to unhappiness. With that in mind,
                        let’s rethink the paths people can take from ages 18...
                      </p>
                      <p>
                        <a
                          href="https://www.garyvaynerchuk.com/the-18-to-28-debate-is-college-worth-it/"
                          class="gv-post-permalink link-blue"
                          >Read More &gt;&gt;</a
                        >
                      </p>
                    </div>
                  </div>
                </article>
              </div> -->
            </div>
            <!-- sidebar -->

            <HomeSideBanner/>
          </div>
        </div>
      </div>
    </article>
    <!-- <div id="overlay-share" class="gv-overlay gv-overlay-black">
      <a
        href="#overlay-share|modal-close"
        class="gv-modal-ctrl gv-close gv-close-gray visible-xs-inline-block"
        >×</a
      >
      <div class="social-container">
        <a
          href="#"
          data-title="A Letter I Wrote In A Locker Room"
          data-permalink="https://www.garyvaynerchuk.com/a-letter-i-wrote-in-a-locker-room/"
          data-platform="facebook"
          class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-facebook"
          title="Share on Facebook"
        >
          <i class="fa fa-facebook"></i>
        </a>
        <a
          href="#"
          data-title="A Letter I Wrote In A Locker Room"
          data-permalink="https://www.garyvaynerchuk.com/a-letter-i-wrote-in-a-locker-room/"
          data-platform="twitter"
          class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-twitter"
          title="Share on Twitter"
        >
          <i class="fa fa-twitter"></i>
        </a>
        <a
          href="#"
          data-title="A Letter I Wrote In A Locker Room"
          data-permalink="https://www.garyvaynerchuk.com/a-letter-i-wrote-in-a-locker-room/"
          data-platform="linkedin"
          class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-linkedin"
          title="Share on LinkedIn"
        >
          <i class="fa fa-linkedin"></i>
        </a>
        <a
          href="#"
          data-title="A Letter I Wrote In A Locker Room"
          data-permalink="https://www.garyvaynerchuk.com/a-letter-i-wrote-in-a-locker-room/"
          data-platform="pinterest"
          data-media="https://www.garyvaynerchuk.com/wp-content/uploads/fce25fc26b97c142a260c4a43b3ea1b5.jpg"
          class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-pinterest"
          title="Share on Pinterest"
        >
          <i class="fa fa-pinterest"></i>
        </a>
        <a
          href="#"
          data-title="A Letter I Wrote In A Locker Room"
          data-permalink="https://www.garyvaynerchuk.com/a-letter-i-wrote-in-a-locker-room/"
          data-platform="googleplus"
          class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-google"
          title="Share on Google+"
        >
          <i class="fa fa-google-plus"></i>
        </a>
        <a
          href="#"
          data-title="A Letter I Wrote In A Locker Room"
          data-permalink="https://www.garyvaynerchuk.com/a-letter-i-wrote-in-a-locker-room/"
          data-platform="reddit"
          class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-reddit"
          title="Share on Reddit"
        >
          <i class="fa fa-reddit"></i>
        </a>
        <a
          href="#"
          data-title="A Letter I Wrote In A Locker Room"
          data-permalink="https://www.garyvaynerchuk.com/a-letter-i-wrote-in-a-locker-room/"
          data-platform="stumble"
          class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-stumble"
          title="Share on StumbleUpon"
        >
          <i class="fa fa-stumbleupon"></i>
        </a>
        <a
          href="#"
          data-title="A Letter I Wrote In A Locker Room"
          data-permalink="https://www.garyvaynerchuk.com/a-letter-i-wrote-in-a-locker-room/"
          data-platform="email"
          class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-email"
          title="Email Us"
        >
          <i class="fa fa-envelope"></i>
        </a>
        <a
          href="#overlay-share|modal-close"
          class="gv-modal-ctrl gv-close gv-close-gray hidden-xs"
          >×</a
        >
      </div>
    </div> -->

    <Footer/>
  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import Footer from '../components/Footer'
import Header from '../components/Header'
import HomeSideBanner from '../components/HomeSideBanner'
import ArticleDetail from '../components/ArticleDetail'
import axios from 'axios'
import BaseUrl from '../assets/constant' 

export default {
  components:{
    Header,
    Footer,
    ArticleDetail,
    HomeSideBanner
  },
  data() {
    return {
      artileData: [],
    };
  },
  methods:{

  },
   async mounted() {
    console.log(this.$route.params.id)
    try { 
      const {data} = await this.$store.dispatch({type: 'getArticleDetail', payload: this.$route.params.id})
      console.log('data data data data',data)
      if(data){
          this.artileData = data
        }   
    } catch (error) {
          console.log(error)
    }
  },
};
</script>
